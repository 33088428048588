import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import * as Yup from 'yup';

import Header from '../../../components/Header';
import getValidationErrors, {
  Errors,
} from '../../../utils/getValidationErrors';
import { useToast } from '../../../hooks/toast';

import { useAuth } from '../../../hooks/auth';
import { Container, ButtonsWrapper } from './styles';
import api from '../../../services/api';
import Title from '../../../components/Title';
import ButtonUI from 'components/ui/ButtonUI';
import SituationFieldset from '../components/SituationFieldset';
import BasicDataFieldset from '../components/BasicDataFieldset';
import { BasicDataT, CustomerPayloadT, CustomerT } from '../utils/types';
import ResetPasswordButton from '../components/ResetPasswordButton';
import { getTypeOrder } from 'pages/LandingPages/utils/helpers';

const webSiteUrl = window.location.origin;

function UserMaintenance() {
  const { id } = useParams<any>();
  const { userId } = useAuth();
  const history = useHistory();
  const { addToast } = useToast();

  const [contracts, setContracts] = useState<CustomerPayloadT[]>([]);
  const [cpfCnpj, setCPFCNPJ] = useState<any>('');

  const [errors, setErrors] = useState<Errors>({});
  const [isEdit, setIsEdit] = useState<any>(false);

  const [situationsChecked, setSituationsChecked] = useState({
    deleted: false,
    inactive: false,
    confirmedEmail: true,
    validProfile: true,
  });
  const [basicData, setBasicData] = useState<BasicDataT>({
    email: '',
    fullname: '',
    password: 'Mudar@123', // Mudar@123 não altera a senha do usuário
    roleId: '',
  });
  const [userOld, setUserOld] = useState<any>();

  useEffect(() => {
    if (id) {
      setIsEdit(true);
      api.get(`/users/getbyuserid/${userId}/${id}`).then((response: any) => {
        const itensCopy = Array.from(contracts);
        setCPFCNPJ(response.data.cpfCnpj);
        setBasicData({
          ...basicData,
          email: response.data.email,
          fullname: response.data.fullname,
          password: response.data.password,
          roleId: response.data.roleID,
        });
        setUserOld(response.data);
        setSituationsChecked({
          ...situationsChecked,
          deleted: !!response.data.deleted,
          inactive: !!response.data.inactive,
          confirmedEmail: !!response.data.emailConfirmed,
          validProfile: !!response.data.checkedOnFleet,
        });
        response.data.userCustomerCMSResponse.map((it: any) => {
          const item = {
            CustomerCMSId: parseInt(it.customerCMSId),
            CustomerName: it.customerName,
          };
          itensCopy.push(item);
        });
        setContracts(itensCopy);
      });
    }
  }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const sharedPayload = {
      roleID: Number(basicData.roleId),
      fullname: basicData.fullname,
      email: basicData.email,
      CustomerCMS: contracts,
    };

    try {
      const schema = Yup.object().shape({
        roleID: Yup.number()
          .moreThan(0, 'Perfil obrigatório')
          .required('Perfil obrigatório'),
        fullname: Yup.string().required('Nome obrigatório'),
        email: Yup.string()
          .required('E-mail obrigatório')
          .email('Digite um e-mail válido'),
        CustomerCMS: Yup.array().required('Contrato obrigatório'),
      });

      await schema.validate(sharedPayload, {
        abortEarly: false,
      });

      if (isEdit) {
        api
          .post('/users/user-maintenance', {
            ...sharedPayload,
            password: basicData.password,
            CheckedOnFleet: situationsChecked.validProfile,
            cpfCnpj: cpfCnpj,
            deleted: situationsChecked.deleted,
            EmailConfirmed: situationsChecked.confirmedEmail,
            inactive: situationsChecked.inactive,
            IsPortalClient: true,
            userId: Number(id),
            UserIdLodado: Number(userId),
            webSiteUrl: webSiteUrl,
            typeOrder: getTypeOrder(),
          })
          .then(() => {
            api
              .post('/crm/reports/UserLog_LMAsync', {
                userId: +id,
                checkedOnFleet: true,
                userResponsavelId: +userId,
                roleId: userOld.roleID,
                nome: userOld.fullname,
                email: userOld.email,
                dados: '',
                dataAlteracao: null,
                customerCMS: userOld.userCustomerCMSResponse,
              })
              .then(() => {
                history.goBack();
              });
            addToast({
              title: 'Edição realizada',
              type: 'success',
              description: 'Seu cadastro foi atualizado com sucesso.',
            });
          })
          .catch((error) => {
            addToast({
              title: 'Erro',
              type: 'error',
              description: error?.response?.data,
            });
          });
      } else {
        api
          .post('/crm/user', sharedPayload)
          .then(() => {
            addToast({
              title: 'Cadastro realizado',
              type: 'success',
              description: 'Seu cadastro foi realizado com sucesso.',
            });
            history.goBack();
          })
          .catch((error) => {
            addToast({
              title: 'Erro',
              type: 'error',
              description: error?.response?.data?.messages.join(', '),
            });
          });
      }
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        setErrors(getValidationErrors(err));
        return;
      }
      addToast({
        type: 'error',
        title: 'Erro na criação/alterar dados do usuário',
        description: err.response.data,
      });
    }
  };

  const handleCancelUser = () => {
    setCPFCNPJ('');
    setBasicData({
      ...basicData,
      email: '',
      fullname: '',
      password: '',
      roleId: '',
    });
    history.goBack();
  };

  const handleRemoveItem = (id: number) => {
    setContracts(contracts.filter((item: any) => item.CustomerCMSId !== id));
  };

  const handleBasicDataChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    setBasicData({ ...basicData, [e.target.name]: e.target.value });
  };

  const handleContractChange = (value: CustomerT) => {
    if (!value) return;
    addContract({
      CustomerCMSId: value.customerId,
      CustomerName: value.descricao,
    });
  };

  const addContract = (value: CustomerPayloadT) => {
    const isDuplicate = (contract: CustomerPayloadT) =>
      contract.CustomerCMSId === value.CustomerCMSId;
    const found = contracts.find(isDuplicate);
    if (!found) setContracts([...contracts, value]);
  };

  const handleSituationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSituationsChecked({
      ...situationsChecked,
      [e.target.name]: e.target.checked,
    });
  };

  return (
    <>
      <Header />

      <Container>
        <Title
          title={isEdit ? 'MANUTENÇÃO DE USUÁRIO' : 'NOVO USUÁRIO'}
          subtitle={'Associe um perfil de acesso à um usuário'}
        />

        <form onSubmit={handleSubmit}>
          {isEdit && (
            <SituationFieldset
              situationsChecked={situationsChecked}
              onChange={handleSituationChange}
            />
          )}

          <BasicDataFieldset
            contracts={contracts}
            errors={errors}
            values={basicData}
            onChange={handleBasicDataChange}
            onContractChange={handleContractChange}
            onRemoveContract={handleRemoveItem}
          />

          <ButtonsWrapper>
            <ButtonUI color="secondary" onClick={handleCancelUser}>
              Cancelar
            </ButtonUI>

            {isEdit && <ResetPasswordButton email={basicData.email} />}

            <ButtonUI type="submit">Salvar</ButtonUI>
          </ButtonsWrapper>
        </form>
      </Container>
    </>
  );
}

export default UserMaintenance;
