import { postResetPasswordApi } from '../utils/services';
import { useToast } from 'hooks/toast';

type Props = {
  email: string;
  messageTitle: string;
};

function useResetPassword({ messageTitle, email }: Props) {
  const { addToast } = useToast();

  const resetPassword = () => {
    postResetPasswordApi(email)
      .then(() => {
        addToast({
          isSuperimposed: true,
          title: messageTitle,
          type: 'success',
          description: `E-mail enviado para ${email}.`,
        });
      })
      .catch(() => {
        addToast({
          isSuperimposed: true,
          title: messageTitle,
          type: 'error',
          description:
            'Não foi possível enviar o e-mail, tente novamente mais tarde.',
        });
      });
  };

  return { resetPassword };
}

export default useResetPassword;
