import api from 'services/api';
import { getTypeOrder } from 'pages/LandingPages/utils/helpers';

export function getSearchCustomerApi(customer: string) {
  return api
    .get(`/customers/SearchCustomerLM/${customer}/rdpn${btoa(customer)}`)
    .then((response) => response.data);
}

export function getRolesApi() {
  return api.get('/roles').then((response) => response.data);
}

export function postResetPasswordApi(email: string) {
  return api.post('/crm/user/reset-password', {
    email,
  });
}
